import React from "react"
import Layout from "../../components/Layout/Layout"
import News from "../../components/Sections/News";

import RestMenu from "../../components/Sections/RestMenu";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGlassMartini, faWineBottle, faMusic} from "@fortawesome/free-solid-svg-icons";
import {Link} from "gatsby";


export default function Menu() {
    return (
        <Layout>
            <div className={"container mt-5 pt-5"}>
                <div className={"row mt-4"}>
                    <div className={"col-12 col-md-4"}>
                        <a target={'_blank'} href="/Jazztikot_napojovy-listok.pdf">
                            <div className="shadow text-center pt-4">
                                <div className="bigIco pb-4">
                                    <FontAwesomeIcon className={"text-secondary bigIco"} icon={faGlassMartini}/>
                                    <div className={"icoText"}>Nápojový lístok</div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className={"col-12 col-md-4"}>
                        <a target={'_blank'} href="/Jazztikot_vinny-listok.pdf">
                            <div className="shadow text-center pt-4">
                                <div className="bigIco pb-4">
                                    <FontAwesomeIcon className={"text-secondary bigIco"} icon={faWineBottle}/> <br/>
                                    <span className={"icoText"}>Vínny lístok</span>
                                </div>

                            </div>
                        </a>
                    </div>
                    <div className={"col-12 col-md-4"}>
                        <Link to={"/qr/program"}>
                            <div className="shadow text-center pt-4">
                                <div className="bigIco pb-4">
                                    <FontAwesomeIcon className={"text-secondary bigIco"} icon={faMusic}/> <br/>
                                    <span className={"icoText"}>Akcie & Koncerty</span>
                                </div>

                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </Layout>
    )
        ;
}