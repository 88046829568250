import React from "react";
import {faGlassMartini, faWineBottle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {StaticImage} from "gatsby-plugin-image";

const RestMenu = () => (
    <section className={"restMenu"}>

        <div className="container">

            <h2 id={"restMenu"} className={"text-center mb-4"}>Menu
                <span> nápojový & vínny lístok </span>
            </h2>

<div className=" mt-5 row d-flex justify-content-evenly">
    <div className="col-12 col-md-3">
        <a target={'_blank'} href="Jazztikot_napojovy-listok.pdf">
        <div className="shadow text-center pt-4">
            <div className="bigIco pb-4">
                <FontAwesomeIcon className={"text-secondary bigIco"} icon={faGlassMartini}/>
                <div className={"icoText"}>Nápojový lístok</div>
            </div>
        </div>
        </a>
    </div>
    <div className="col-12 col-md-3">
        <a target={'_blank'} href="Jazztikot_vinny-listok.pdf">
        <div className="shadow text-center pt-4">
            <div className="bigIco pb-4">
                <FontAwesomeIcon className={"text-secondary bigIco"} icon={faWineBottle}/> <br/>
                <span className={"icoText"}>Vínny lístok</span>
            </div>

        </div>
        </a>
    </div>
</div>


            <div className="row mt-4 d-flex justify-content-center">

                <div className="col-12 col-sm-2 text-center">

                    <StaticImage imgClassName={"imgHoverEffect"} src="../../assets/custom-style/images/partners/svijany.jpg" alt="Pivovar Svijany"/>

                </div>
                <div className="col-12 col-sm-2 text-center">
                    <StaticImage imgClassName={"imgHoverEffect"} src="../../assets/custom-style/images/partners/trieste.jpg" alt="Caffé Trieste"/>
                </div>


            </div>



        </div>
    </section>

);

export default RestMenu

